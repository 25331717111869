// Example of how a component should be initialized via JavaScript
// This script logs the value of the component's text property model message to the console
// eslint-disable-next-line sonarjs/cognitive-complexity
;(function () {
  'use strict'
  function onDocumentReady() {
    const slideImagesAreas = document.querySelectorAll('.cmp-side-by-side-images')
    let touchPos

    for (const slideImagesArea of slideImagesAreas) {
      // store the touching position at the start of each touch
      slideImagesArea.addEventListener('touchstart', function (event) {
        touchPos = event.changedTouches[0].clientX
      })

      // detect wether the "old" touchPos is
      // greater or smaller than the newTouchPos
      slideImagesArea.addEventListener('touchmove', function (event) {
        let newTouchPos = event.changedTouches[0].clientX
        if (newTouchPos > touchPos) {
          slideImagesArea.classList.remove('cmp-side-by-side-images--is-scrolled-right')
          slideImagesArea.scrollLeft = 0
        }
        if (newTouchPos < touchPos) {
          slideImagesArea.classList.add('cmp-side-by-side-images--is-scrolled-right')
          slideImagesArea.scrollLeft = slideImagesArea.scrollWidth
        }
      })
    }
  }

  if (document.readyState !== 'loading') {
    onDocumentReady()
  } else {
    document.addEventListener('DOMContentLoaded', onDocumentReady)
  }
})()
