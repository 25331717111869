import { unitSystemToSymbol } from '@utils/sphericalDistance'

import { PartnerTag, StoreInfo, StoreLocatorConfig, StoreLocatorStoresResponse } from './StoreLocatorTypes'

// const DIRECTIONS_BASE_URL = 'https://www.google.com/maps/dir/?'

export function createInfoWindowForPartner({
  locationEntry,
  texts,
  unitOfMeasure,
  distance,
  categories,
  premiumCategoryId,
}: {
  locationEntry: StoreInfo
  texts: StoreLocatorConfig['texts']
  distance?: string
  unitOfMeasure: StoreLocatorConfig['unitMeasurement']
  categories: StoreLocatorStoresResponse['categories']
  premiumCategoryId: string | null
}) {
  const {
    lat,
    lng,
    companyName,
    contactCategories,
    address1,
    address2,
    zipCode,
    city,
    phone,
    email,
    website,
    websiteRelAttribute,
  } = locationEntry
  const destination = `${lat},${lng}`
  const parameters = new URLSearchParams()
  parameters.append('api', '1')
  parameters.append('destination', destination)
  const categoriesMap = Object.fromEntries<PartnerTag>(categories.map((c) => [c.id, c]))

  // const navigateUrl = `${DIRECTIONS_BASE_URL}${parameters.toString()}`
  const websiteLink =
    website?.startsWith('https://') || website?.startsWith('http://') || !website ? website : `https://${website}`

  const distanceString = `<div class="cmp-partner-finder-info-window__distance">${distance} ${unitSystemToSymbol[unitOfMeasure]} ${texts.fromYou}</div>`
  const categoriesString = contactCategories
    .map((category) => `<span class="cmp-partner-finder-info-window__category">${categoriesMap[category].label}</span>`)
    .join('')
  const phoneString = phone
    ? `<a class="cmp-partner-finder-info-window__link cmp-partner-finder-info-window__link--phone" href="tel:${phone}">
      <i class="ri-smartphone-line cmp-partner-finder-info-window__icon"></i>
      ${phone}
    </a>`
    : ''
  const emailString = email
    ? `<a class="cmp-partner-finder-info-window__link" href="mailto:${email}">
      <i class="ri-mail-line cmp-partner-finder-info-window__icon"></i>
      ${email}
    </a>`
    : ''
  const websiteString = website
    ? `<a class="cmp-partner-finder-info-window__link" target="_blank" href="${websiteLink}" rel="${
        websiteRelAttribute || ''
      }">
      <i class="ri-global-line cmp-partner-finder-info-window__icon"></i>
      ${website}
    </a>`
    : ''
  const linkSection =
    phoneString.length > 0 || emailString.length > 0 || websiteString.length > 0
      ? `<div class="cmp-partner-finder-info-window__section">
      ${phoneString}
      ${emailString}
      ${websiteString}
    </div>`
      : ''

  const content = `
    <div class="cmp-partner-finder-info-window ${
      premiumCategoryId && contactCategories.includes(premiumCategoryId)
        ? 'cmp-partner-finder-info-window--is-premium'
        : ''
    }">
      <header class="cmp-partner-finder-info-window__header">
        ${distance !== undefined ? distanceString : ''}
        <h4 class="cmp-partner-finder-info-window__title">${companyName}</h4>
      </header>
      <section class="cmp-partner-finder-info-window__body">
        <div class="cmp-partner-finder-info-window__section">${categoriesString}</div>
        <div class="cmp-partner-finder-info-window__section">
          ${[address1, address2 ? ' ' : undefined, address2, ', ', zipCode, ' ', city].filter(Boolean).join('')}
        </div>
        ${linkSection}
        <a
          class="cmp-partner-finder-info-window__google-link"
          target="_blank"
          href="https://www.google.com/maps/search/?api=1&query=${lat}%2C${lng}"
        >
          ${texts.googleMapsLinkLabel}
        </a>
      </section>
    </div>
  `

  return new google.maps.InfoWindow({
    content,
    ariaLabel: companyName,
    minWidth: 294,
  })
}
